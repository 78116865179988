.queries {
  color: #eee;
  margin-right: 20px;

  .MuiButtonBase-root {
    color: #eee;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
  }
}
