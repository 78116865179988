* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
}

#app {
  overflow: hidden;
  height: calc(100vh - var(--topbar-height));
  margin-top: var(--topbar-height);
  background-color: var(--color-white);
}
body {
  min-height: 80vh;
}

.react-flow__panel.bottom {
  bottom: 120px !important;
}

.edge-info {
  font-size: 12;
  background-color: #fff;
  pointer-events: all;
  text-align: center;
  position: absolute;
}

.react-flow__renderer {
  height: 90% !important;
}
