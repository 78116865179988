#access-denied {
  height: 100vh;
  font-family: Avenir;
  text-align: center;

  .warning-icon {
    margin: 1rem;
    height: 100px;
    width: auto;
  }

  .main-message {
    font-size: 2.25rem;
    font-weight: 900;
  }

  .button-wrapper {
    position: absolute;
    width: 100vw;
    bottom: 40px;
  }
}