.topbar {
  background-color: var(--color-carbone);
  height: var(--topbar-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  button {
    text-transform: capitalize;
    color: var(--color-white);
    font-weight: 500;
    font-family: Avenir;
    font-size: 16px;
  }

  .app-name {
    color: var(--color-white);
    font-weight: 500;
    font-family: Avenir;
  }

  .menu {
    display: flex;
    align-items: center;
  }
}

.menu-item-title {
  padding-top: 1px;
  font-family: Avenir;
  color: var(--color-carbone);
}
